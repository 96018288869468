<template>
  <div class="fill-height d-flex flex-column">

    <ToolbarTop
      :loading="loading"
      :mini="mini"
      :school-subjects="schoolSubjects"
      :filter-by-school-subject="filterBySchoolSubject"
      @input:filter-by-school-subject="setFilterBySchoolSubject"
      @input:context-object="setContextObject"
      @input:date-interval="setDateInterval"
    />

    <StickyHeaderList class="fill-height pa-2 pa-sm-4 pa-lg-8">
      <StickyHeaderListItem
        v-for="week in weeks"
        :key="week.weekId"
      >
        <template v-slot:sticky-header>
          <span class="d-flex">
            {{ week.text }}
            <small class="ml-1">
              ({{ week.startsOn | formatDate }} - {{ week.endsOn | formatDate }})
            </small>
          </span>
        </template>

        <WeekReportCard
          v-for="schoolSubject in filteredSchoolSubjects"
          :key="schoolSubject['@id']"
          :school-subject="schoolSubject"
          :week-report="findWeekReport(schoolSubject['@id'], week.weekId)"
          @edit-week-report="showEditWeekReportDialog(week.weekId, schoolSubject)"
        />

      </StickyHeaderListItem>
    </StickyHeaderList>

    <ToolbarBottom
      v-if="mini"
      :loading="loading"
      @input:date-interval="setDateInterval"
    />

    <EditWeekReportDialog
      :week-report="editWeekReport"
      @save:done="onSaveWeekReport"
      @close="editWeekReport = null"
    />

    <AppLoader v-show="loading" />

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { api } from '@/api'
import AppLoader from '@/components/app-loader'
import ToolbarTop from '@/pages/week-reports.page/toolbar-top'
import ToolbarBottom from '@/pages/week-reports.page/toolbar-bottom'
import StickyHeaderList from '@/components/sticky-header-list'
import StickyHeaderListItem from '@/components/sticky-header-list/item'
import WeekReportCard from '@/pages/week-reports.page/week-report-card'
import EditWeekReportDialog from '@/components/edit-week-report-dialog'
import { formatDate } from '@/helpers/datetime'

const weekReportKey = (schoolSubjectIri, weekId) => `${weekId}-${schoolSubjectIri}`

export default {
  name: 'WeekReportsPage',
  components: {
    AppLoader,
    ToolbarTop,
    ToolbarBottom,
    StickyHeaderList,
    StickyHeaderListItem,
    WeekReportCard,
    EditWeekReportDialog
  },
  filters: {
    formatDate
  },
  data: () => ({
    loadingSchoolSubjects: null,
    schoolSubjects: [],
    filterBySchoolSubject: null,
    loadingWeekReports: null,
    weekReports: new Map(),
    editWeekReport: null
  }),
  computed: {
    ...mapGetters('common', [
      'courseByIri',
      'schoolSubjectByIri',
    ]),
    ...mapState(['contextObject']),
    ...mapGetters('schoolCalendar', ['weeksByDates']),
    ...mapState('schoolCalendar', [
      'academicYear',
      'section',
      'startDate',
      'endDate'
    ]),
    loading() {
      return this.loadingSchoolSubjects || this.loadingWeekReports || this.$store.getters['common/loading']
    },
    mini() {
      return this.$vuetify.breakpoint.xsOnly
    },
    showWelcomePage() {
      const state = this.$store.state.common
      return state.academicYears.length == 0
        || state.sections.length == 0
        || state.schoolSubjects.length == 0
    },
    weeks() {
      const startDate = this.startDate || this.section?.startsOn
      const endDate = this.endDate || this.section?.endsOn
      return this.weeksByDates(startDate, endDate)
    },
    filteredSchoolSubjects() {
      if (!this.filterBySchoolSubject) {
        return this.schoolSubjects
      }
      return this.schoolSubjects.filter(item => item === this.filterBySchoolSubject)
    }
  },
  watch: {
    filterBySchoolSubject() {
      this.loadWeekReports()
    }
  },
  async created() {
    const { dispatch } = this.$store
    await this.loadCommonData()
    if (this.showWelcomePage) {
      this.$router.push({name: 'welcome'})
      return
    }
    await dispatch('schoolCalendar/setNearestAcademicYear')
    await dispatch('schoolCalendar/setNearestSection')
    await Promise.all([
      dispatch('common/fetchSchoolClassesByAcademicYear', this.academicYear),
      dispatch('common/fetchCoursesBySection', this.section),
    ])
    await dispatch('schoolCalendar/setNearestSchoolWeek')
    dispatch('resetContextObject', ['SchoolClass'])
    this.loadSchoolSubjects()
    this.loadWeekReports()
  },
  methods: {
    loadCommonData() {
      return this.$store.dispatch('common/fetchCollectionOnce', [
        'academicYears',
        'schoolSubjects',
        'sections'
      ])
    },
    async loadSchoolSubjects() {
      this.schoolSubjects = []
      const { section, contextObject } = this
      if (!(section && contextObject)) return
      const startDate = this.startDate || section.startsOn
      const endDate = this.endDate || section.endsOn
      const url = `${contextObject['@id']}/actual_lessons`
      const groups = ['actual_lesson:full', 'actual_lesson:include_scheduled_lesson']
      const params = {startDate, endDate, groups}
      this.loadingSchoolSubjects = true
      try {
        const response = await api.get(url, {params})
        const scheduledLessons = response.data.map(al => al.scheduledLesson)
        const extractSchoolSubjectIri = sl => sl.schoolSubject || this.courseByIri(sl.course)?.schoolSubject
        this.schoolSubjects = [...new Set(scheduledLessons.map(extractSchoolSubjectIri))]
          .filter(iri => !!iri)
          .map(iri => this.schoolSubjectByIri(iri))
      } catch (e) {
        this.schoolSubjects = []
        console.error(e)
      } finally {
        this.loadingSchoolSubjects = false
      }
    },
    async loadWeekReports() {
      this.weekReports = new Map()
      const { weeks, contextObject } = this
      if (!(weeks.length > 0 && contextObject)) return
      const schoolClass = contextObject['@id']
      const calendarWeekCode = weeks.map(w => w.weekId)
      const schoolSubject = this.filterBySchoolSubject?.['@id'] || undefined
      const params = {pagination: false, schoolClass, calendarWeekCode, schoolSubject}
      this.loadingWeekReports = true
      try {
        const data = (await api.get('/api/week_reports', {params})).data['hydra:member']
        this.weekReports = new Map(data.map(weekReport => {
          const key = weekReportKey(weekReport.schoolSubject, weekReport.calendarWeekCode)
          return [key, weekReport]
        }))
      } catch (e) {
        this.weekReports = new Map()
        console.error(e)
      } finally {
        this.loadingWeekReports = false
      }
    },
    async setDateInterval(value) {
      const { commit, dispatch } = this.$store
      const { academicYear, section } = value || {}
      const promises = []
      if (academicYear && academicYear.year !== this.academicYear?.year) {
        promises.push(
          dispatch('common/fetchSchoolClassesByAcademicYear', academicYear)
        )
      }
      if (section && section['@id'] !== this.section?.['@id']) {
        promises.push(
          dispatch('common/fetchDayPlansBySection', section),
          dispatch('common/fetchCoursesBySection', section)
        )
      }
      commit('schoolCalendar/SET_STATE', value)
      await Promise.all(promises)
      dispatch('resetContextObject')
      this.loadSchoolSubjects()
      this.loadWeekReports()
    },
    setContextObject(contextObject) {
      this.$store.commit('SET_CONTEXT_OBJECT', contextObject)
      this.loadSchoolSubjects()
      this.loadWeekReports()
    },
    setFilterBySchoolSubject(schoolSubject) {
      this.filterBySchoolSubject = schoolSubject
    },
    showEditWeekReportDialog(weekId, schoolSubject) {
      const weekReport = this.findWeekReport(schoolSubject['@id'], weekId)
      this.editWeekReport = weekReport || {
        schoolClass: this.contextObject['@id'],
        schoolSubject: schoolSubject['@id'],
        calendarWeekCode: weekId,
        text: ''
      }
    },
    async onSaveWeekReport(weekReport) {
      const key = weekReportKey(weekReport.schoolSubject, weekReport.calendarWeekCode)
      this.weekReports.set(key, weekReport)
      this.editWeekReport = null
    },
    findWeekReport(schoolSubjectIri, weekId) {
      return this.weekReports.get(weekReportKey(schoolSubjectIri, weekId))
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.title {
  font-size: 14px !important;
  background: $default-school-subject-color;
  cursor: default;
}
</style>
